<template>
  <div class="orderDetail">
    <div class="participants">
      <div class="title">参与人</div>
      <div class="body">
        <div class="newPeople">
          <div class="item" v-for="(item, index) in peopleList" :key="index">
            <div class="item-t">
              <img src="@/assets/img/default_avatar.png" alt="" />
            </div>
            <div class="item-c">
              <div class="name">{{ item.applicantsName }}</div>
              <div class="tel">{{ item.applicantsPhone }}</div>
            </div>
            <!-- <div class="item-b" @click.stop="deleteRelation(item)">
              <div class="item-bl"><img src="./img/delete.png" alt="" /></div>
              <div class="item-br">删除</div>
            </div> -->
          </div>
          <!-- <div class="item te" @click="toAdd">
            <div class="item-t"><img src="./img/newAdd.png" alt="" /></div>
            <div class="item-c">
              <div class="name">添加成员</div>
            </div>
          </div> -->
        </div>
        <!-- <div class="person">
          <div class="item">
            <div class="item-l">姓名:</div>
            <div class="item-r">{{ userInfo.userName }}</div>
          </div>

          <div class="item">
            <div class="item-l">性别:</div>
            <div class="item-r">{{ userInfo.sex == 1 ? "男" : "女" }}</div>
          </div>
          <div class="item">
            <div class="item-l">手机号:</div>
            <div class="item-r">{{ userInfo.mobile }}</div>
          </div>
          <div class="num">
            <div class="item-l">总参与人数:</div>
            <div class="item-r">
              <v-stepper
                v-model="joinUserCount"
                :min="1"
                :max="activityDetail.perLimitCount"
              ></v-stepper>
            </div>
          </div>
        </div> -->
        <!-- <div class="people">
          <div
            class="item"
            v-for="(item, index) in peopleList"
            :key="index"
            @click="toPeople(item)"
          >
            <div class="item-l">
              <div class="headImg"><img src="./img/user.png" alt="" /></div>
              <div class="name">{{ item.applicantsName }}</div>
              <div class="tel">{{ item.applicantsPhone }}</div>
            </div>
            <div class="item-r">
              <div class="arrow"><img src="./img/tip.png" alt="" /></div>
            </div>
          </div>
        </div>
        <div class="btn" @click="toAdd">
          <div class="btn-l"><img src="./img/add.png" alt="" /></div>
          <div class="btn-r">添加成员</div>
        </div> -->
      </div>
    </div>
    <div class="partDetail">
      <div class="title">参与详情</div>
      <div class="body">
        <div class="actiName">
          <div class="item">
            <div class="item-l">活动名称</div>
            <div class="item-r">{{ activityDetail.activityName }}</div>
          </div>
        </div>
        <div class="time">
          <div class="item">
            <div class="item-l">报名开始时间</div>
            <div class="item-r">
              {{
                myActivity.scheduleList && myActivity.scheduleList[0]
                  ? myActivity.scheduleList[0].applyStartDate
                  : activityDetail.applyStartDate
              }}
            </div>
          </div>
          <div class="item">
            <div class="item-l">活动开始时间</div>
            <div class="item-r">
              {{
                myActivity.scheduleList && myActivity.scheduleList[0]
                  ? myActivity.scheduleList[0].startDate
                  : activityDetail.startDate
              }}
            </div>
          </div>
        </div>
        <div class="address">
          <div class="item">
            <div class="item-l">活动地点</div>
            <div class="item-r">
              {{ activityDetail.activityAddress }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="btnSubmit">
      <div class="cancel" v-if="isSuccess" @click="cancel">取消报名</div>
    </div>
    <v-dialog v-model="isDialog" title="提示" @confirm="confirm">
      <p style="text-align: center; line-height: 40px">是否确认取消报名？</p>
    </v-dialog>
  </div>
</template>

<script>
import {
  getActivityDetailUrl,
  addPeopleListUrl,
  applyActivityUrl,
  cancelActivityUrl,
  activityDetailUrl,
  userInfoUrl,
} from "./api.js";
import { handleImg } from "@/utils/utils";
export default {
  name: "orderDetail",
  data() {
    return {
      userInfo: {},
      isSuccess: false,
      activityDetail: { scheduleList: {} },
      peopleList: [],
      applicantsIdList: [],
      activityId: "",
      timer: null,
      applyId: "",
      myActivity: {},
      isDialog: false,
      scheduleId: "",
    };
  },
  destroyed() {
    clearTimeout(this.timer);
  },
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    houseId() {
      return this.$store.state.houseId;
    },
  },
  mounted() {
    this.getActivityDetail();
    this.getMyDetail();
    this.getPeopleList();
  },
  created() {
    this.activityId = this.$route.query.activityId;
    this.scheduleId = this.$route.query.scheduleId;
    if (this.$route.query.applyId) {
      this.applyId = this.$route.query.applyId;
    } else {
      this.applyId = 0;
    }
  },
  methods: {
    getMyDetail() {
      let params = {
        activityId: this.activityId,
        userId: this.userId,
        applyId: this.applyId,
      };
      this.$axios.get(`${activityDetailUrl}`, { params }).then((res) => {
        if (res.code == 0 && res.msg == "您还没有报名该活动！") {
          this.isSuccess = false;
        } else {
          this.myActivity = res.data;
          this.isSuccess = true;
        }
      });
    },
    cancel() {
      this.isDialog = true;
    },
    confirm() {
      let params = {
        activityId: this.activityId,
        userId: this.userId,
        applyId: this.applyId,
      };
      this.$axios
        .post(`${cancelActivityUrl}`, this.$qs.stringify(params))
        .then((res) => {
          if (res.code === 200) {
            this.$toast({ message: "操作成功", duration: 300 });
            // this.timer = setTimeout(() => {
            //   this.$router.replace({
            //     name: "myActivityList",
            //   });
            // }, 400);
            // this.$router.replace({
            //   name: "myActivityList",
            // });
            this.$router.go(-1);
          } else {
            if (res.msg) {
              this.$toast({ message: res.msg, duration: 300 });
            }
          }
        });
    },
    sureApply() {
      if (this.applicantsIdList.length == 0) {
        this.$toast({ message: "请添加参与人" });
        return;
      }
      let params = {
        activityId: this.activityId,
        applicantsIdList: this.applicantsIdList,
        houseId: this.houseId,
        userId: this.userId,
      };
      this.$axios
        .post(`${applyActivityUrl}`, this.$qs.stringify(params))
        .then((res) => {
          if (res.code === 200) {
            this.$toast({ message: "操作成功", duration: 300 });
            // this.timer = setTimeout(() => {
            //   this.$router.replace({
            //     name: "myActivityList",
            //   });
            // }, 400);
            this.$router.replace({
              name: "myActivityList",
            });
          } else {
            if (res.msg) {
              this.$toast({ message: res.msg, duration: 300 });
            }
          }
        });
    },

    toAdd() {
      this.$router.push({
        name: "addUser",
        query: { activityId: this.activityId },
      });
    },
    getPeopleList() {
      this.peopleList = [];
      let params = {
        activityId: this.activityId,
        userId: this.userId,
        scheduleId: this.scheduleId,
      };

      this.$axios.get(`${addPeopleListUrl}`, { params }).then((res) => {
        console.log(res, 5555);
        if (res.code === 200) {
          this.peopleList = res.data;
          this.peopleList.forEach((ele) => {
            this.applicantsIdList.push(ele.applicantsId);
          });
        } else {
        }
      });
    },
    getActivityDetail() {
      let params = {
        activityId: this.activityId,
        userId: this.userId,
      };

      this.$axios.get(`${getActivityDetailUrl}`, { params }).then((res) => {
        if (res.code === 200) {
          this.activityDetail = res.data;
          if (this.activityDetail.activityPicture) {
            this.activityDetail.activityPicture = handleImg(
              1500,
              1500,
              this.activityDetail.activityPicture
            );
          }
        } else {
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.orderDetail {
  min-height: 100vh;
  .title {
    position: relative;
    padding-left: 24px;
    font-size: 32px;
    font-weight: bold;
    color: #323334;
    line-height: 44px;
    &::before {
      content: "";
      position: absolute;
      top: 5px;
      left: 0;
      width: 8px;
      height: 32px;
      background: #fe6f16;
      border-radius: 4px;
    }
  }
  .participants {
    background: #ffff;
    padding: 32px 32px 0;
    .body {
      padding: 32px 0 82px;
    }
    .newPeople {
      .item {
        text-align: center;
        display: inline-block;
        vertical-align: middle;
        margin-right: 48px;
        width: 160px;
        height: 262px;
        .item-t {
          width: 112px;
          height: 112px;
          margin: 0 auto;
          img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
          }
        }
        .item-c {
          .name,
          .tel {
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .name {
            font-size: 32px;
            font-weight: bold;
            color: #323334;
            margin: 8px 0;
            line-height: 44px;
          }
          .tel {
            font-size: 24px;
            font-weight: 400;
            color: #323334;
            line-height: 36px;
            margin-bottom: 8px;
          }
        }
        .item-b {
          display: flex;
          align-items: center;
          justify-content: center;
          .item-bl {
            width: 32px;
            height: 32px;
            margin-right: 2px;
            font-size: 0;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .item-br {
            font-size: 24px;
            font-weight: 400;
            color: #cbcccd;
            line-height: 36px;
          }
        }
      }
      .te {
        .item-c {
          .name {
            font-size: 32px;
            font-weight: bold;
            color: #cbcccd;
            line-height: 44px;
          }
        }
      }
    }
    .person {
      font-size: 30px;
      padding: 20px 0 0 0;
      .item {
        display: flex;
        margin-bottom: 16px;
        .item-l {
          width: 180px;
        }
      }
      .num {
        display: flex;
        .item-l {
          width: 180px;
        }
        .item-r {
          flex: 1;
        }
      }
    }
    .people {
      .item {
        display: flex;
        justify-content: space-between;
        margin: 30px 0;
        .item-l {
          display: flex;
          align-items: center;
          .headImg {
            width: 48px;
            height: 48px;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .name {
            width: 300px;
            font-size: 30px;
            margin: 0 20px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          .tel {
            font-size: 30px;
          }
        }
        .item-r {
          display: flex;
          align-items: center;
          .code {
            display: flex;
            align-items: center;
            .code-l {
              width: 40px;
              height: 40px;
              img {
                width: 100%;
                height: 100%;
              }
            }
            .code-r {
              font-size: 30px;
            }
          }
          .arrow {
            width: 40px;
            height: 40px;
            margin-left: 16px;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
  }
  .partDetail {
    background: #ffff;
    padding: 0 32px;
    .body {
      padding: 32px 0 0 0;
    }
    .actiName,
    .time,
    .address {
      .item {
        display: flex;
        align-items: flex-start;
        margin-bottom: 32px;
      }
      .item-l {
        width: 168px;
        margin-right: 48px;
        font-size: 28px;
        font-weight: 400;
        color: #323334;
        line-height: 40px;
      }
      .item-r {
        flex: 1;
        font-size: 28px;
        font-weight: 400;
        color: #97999b;
        line-height: 40px;
      }
    }
    .people {
    }
  }
  .btnSubmit {
    width: 100%;
    position: fixed;
    z-index: 1;
    bottom: 0;
    left: 0;
    font-size: 32px;
    line-height: 96px;
    .submit {
      height: 96px;
      background: #009aff;
      border: 1px solid #ededf0;
      text-align: center;
      color: #fff;
    }
    .cancel {
      height: 96px;
      background: #009aff;
      //border: 1px solid red;
      text-align: center;
      color: #fff;
    }
  }
}
</style>
